<template>
  <div class="page-sms-template-detail">
    page {{ title }} 1111is working!
  </div>
</template>

<script lang="js">
import SmsTemplateDetailPage from './sms-template-detail';

export default SmsTemplateDetailPage;
</script>

<style lang="scss">
@import "./sms-template-detail";
</style>
